/* global */
.container-blue {
  background-color: var(--color-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 20px;
  padding: 20px;
  transition: 0.5s;
}

.container-blue:hover {
  box-shadow: 0px 0px 10px var(--color-grey);
}

.container-light-blue {
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 20px;
  padding: 20px;
  transition: 0.5s;
}

.container-light-blue:hover {
  background-color: var(--color-blue);
  border-color: var(--color-light-blue);
  box-shadow: 0px 0px 10px var(--color-light-blue);
}

.l-text {
  font-size: 35px
}

.m-text {
  font-size: 25px
}

.m-s-text {
  font-size: 17px;
}

.s-text {
  font-size: 15px;
}

.bold {
  font-weight: bold;
}

.pink-text {
  color: var(--color-pink);
}

.grey-text {
  color: var(--color-grey);
}

.white-text {
  color: white;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link-pink {
  color: var(--color-pink);
}

.link-black {
  color: black;
}

.btn {
  padding: 12px 15px;
  color: white;
  background-color: #E60F73;
  border: 1px solid var(--color-pink);
  border-radius: 10px;
  font-size: 15px;
  transition: 0.5s;
}

.btn:hover {
  background-color: var(--color-light-blue);
  border-color: var(--color-grey);
}

@keyframes fadein {
  0% {
    opacity: 0%;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 100%;
  }
}

/* Navbar */
.nav-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  opacity: 0%;
  animation: fadein 0.5s linear 1s;
  transition: 0.5s;
}

.navigation {
  width: fit-content;
  padding: 15px;
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-grey);
  border-radius: 20px;
  box-shadow: 0px 0px 10px black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 0.5s;
}

.nav-items {
  width: 40px;
  height: 40px;
  margin-inline: 10px;
  background-color: var(--color-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.nav-items img {
  width: fit-content;
  height: 15px;
  object-position: center;
}

.nav-items:hover {
  background-color: var(--color-light-blue);
  border-color: var(--color-grey);
}

.active-item {
  border-color: var(--color-pink);
}

.divider {
  width: 2px;
  margin-inline: 20px;
  background-color: var(--color-grey);
  transition: 0.5s;
}

.nav-selected-item {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.nav-select-text {
  color: white;
  text-transform: uppercase;
}

.copyright {
  padding: 5px 10px;
  margin-top: 10px;
  color: white;
  background-color: var(--color-blue);
  border-radius: 10px;
}

/* Home */
.home-container {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  opacity: 0%;
  animation: fadein 1s linear 1s;
}

.home-info {
  margin: 30px 200px;
  transition: 0.5s;
}

.intro-1 {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}

.intro-2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  transition: 0.5s;
}

.photo {
  width: 200px;
  height: 200px;
  border: 1px solid var(--color-light-blue);
  border-radius: 20px;
}

.contact-me-btn {
  margin-top: 20px;
}

.social-links {
  margin: 20px 0px;
}

.social {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.social-svg-path {
  transition: 0.5s;
}

.social-svg-path:hover {
  fill: var(--color-pink);
}

.intro-3 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}

.about-me-btn {
  margin-top: 20px;
}

.latest-project-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.latest-project-card img {
  width: 100px;
  margin-top: 10px;
  border-radius: 15px;
  transition: 0.5s;
}

.latest-project-card span {
  width: 200px;
  margin-inline: 10px;
  transition: 0.5s;
}

/* About */
.about-container {
  position: absolute;
  width: 100%;
  padding-block: 30px;
  background-color: var(--color-blue);
  opacity: 0%;
  animation: fadein 1s linear 1s;
  transition: 0.5s;
}

.about-info {
  margin-inline: 200px;
  transition: 0.5s;
}

.info-photo-text {
  display: flex;
  flex-direction: row;
}

.info-photo img {
  width: 170px;
  height: 170px;
  border-radius:20px;
}

.info-text {
  padding-left: 20px;
}

.certificates {
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.certificates > img {
  width: 300px;
  height: 190px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px var(--color-grey);
}

.projects {
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.project-card {
  padding-inline: 10px;
  margin-right: 10px;
  background-color: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;
}

.project-card:hover {
  background-color: var(--color-blue);
  border-color: var(--color-light-blue);
  box-shadow: 0px 0px 10px var(--color-light-blue);
}

.project-card img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.project-card p {
  padding-left: 10px;
  width: 200px;
}

.skills-card {
  display: grid;
  grid-template-columns: auto auto;
  transition: 1s;
}

.myskill {
  padding-inline: 10px;
}

.progress {
  width: 500px;
  height: 10px;
  background-color: white;
  border-radius: 2px;
  transition: 1s;
}

.progress::after {
  content: '';
  width: 0%;
  height: 10px;
  position: absolute;
  background-color: var(--color-pink);
  border-radius: 2px;
  transition: 1s;
}

#frontend::after {
  width: 450px;
}

#frontendframeworks::after {
  width: 475px;
}

#backend::after {
  width: 470px;
}

#backendframeworks::after {
  width: 460px;
}

#uiux::after {
  width: 400px;
}

#dsa::after {
  width: 400px;
}

#appdev::after {
  width: 425px;
}

#database::after {
  width: 400px;
}

#cloud::after {
  width: 350px;
}

#git::after {
  width: 475px;
}

.college {
  margin-top: 10px;
  margin-bottom: 250px;
}

/* Contact */
.contact-container {
  position: absolute;
  width: 100%;
  padding-block: 70px;
  background-color: var(--color-blue);
  display: flex;
  justify-content: center;
  opacity: 0%;
  animation: fadein 1s linear 1s;
  transition: 0.5s;
}

.contact-info {
  width: fit-content;
  height: fit-content;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 20px; */
}

.contact-from {
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
}

.inputs {
  width: 100%;
  padding: 15px;
  margin: 10px 0px;
  border: 1px solid var(--color-light-blue);
  border-radius: 10px;
  color: white;
  background-color: var(--color-blue);
  outline: none;
  transition: 0.5;
}

.inputs:hover {
  border: 1px solid var(--color-pink);
}

.submit-btn {
  width: 109%;
  height: 45px;
  transition: 0.5s;
}

.contact-my-info {
  padding: 20px;
  background-color: var(--color-blue);
  border: 1px solid var(--color-light-blue);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.information div {
  padding-block: 10px;
  display: flex;
  align-items: center;
}

.information span {
  padding-left: 10px;
  text-align: center;
}

.location img {
  width: 25px;
  height: 30px;
}

.mail img {
  width: 30px;
  height: 30px;
}

#contact-connect-me {
  margin-top: 20px;
}

.alert {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px 10px;
  background-color: var(--color-light-blue);
  display: flex;
  align-items: baseline;
}

.alert p {
  flex-grow: 1;
}

.alert button {
  margin: 5px 20px;
}

/* Blog */
.blog-container {
  position: absolute;
  width: 100%;
  background-color: var(--color-blue);
  opacity: 0%;
  animation: fadein 1s linear 1s;
  transition: 0.5s;
}

.blog-info {
  margin: 30px 200px;
  transition: 0.5s;
}

.blogs {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  transition: 0.5s;
}

.blog {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}

/* Media Queries */
@media screen and (max-width: 1500px) {
  /* About */
  .about-info {
    margin: 30px 100px;
  }

  /* Blog */
  .blog-info {
    margin: 30px 100px;
  }
}

@media screen and (max-width: 1300px) {
  /* Home */
  .home-info {
    margin: 30px 100px;
  }

  /* About */
  .about-info {
    margin: 30px 50px;
  }

  /* Blog */
  .blog-info {
    margin: 30px 50px;
  }
}

@media screen and (max-width: 1200px) {
  /* Home */
  .home-info {
    margin: 30px 50px;
  }

  /* About */
  .skills-card {
    grid-template-columns: auto;
    width: 100%;
  }

  /* Blog */
  .blogs {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 1000px) {
  /* Home */
  .home-info {
    margin: 30px 20px;
  }

  #contact-me {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  /* Home */
  .intro-2 {
    grid-template-columns: auto auto;
  }

  /* Contact */
  .contact-info {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 800px) {
  /* Home */
  .intro-3 {
    grid-template-columns: auto;
    margin-bottom: 200px;
  }

  .latest-project-card span {
    width: 100%;
  }

  /* Blog */
  .blog-info {
    margin: 30px 20px;
  }
}

@media screen and (max-width: 700px) {
  /* Home */
  .intro-2 {
    grid-template-columns: auto;
    place-items: center;
  }

  /* About */
  .about-info {
    margin: 30px 20px;
  }

  /* Blog */
  .blogs {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 600px) {

  /* About */
  .progress {
    width: 250px;
  }

  #frontend::after {
    width: 225px;
  }

  #frontendframeworks::after {
    width: 237.5px;
  }

  #backend::after {
    width: 235px;
  }

  #backendframeworks::after {
    width: 230px;
  }

  #uiux::after {
    width: 200px;
  }

  #dsa::after {
    width: 200px;
  }

  #appdev::after {
    width: 212.5px;
  }

  #database::after {
    width: 200px;
  }

  #cloud::after {
    width: 175px;
  }

  #git::after {
    width: 237.5px;
  }
}

@media screen and (max-width: 425px) {
  /* Home */
  .photo {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 550px) {
  /* Home */
  .intro {
    padding-inline: 10px;
  }

  .s-text {
    width: 100%;
  }

  .name {
    font-size: 40px;
  }

  .social {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 500px) {

  /* Navbar */
  .divider {
    display: none;
  }

  .nav-selected-item {
    display: none;
  }

  /* Home */
  .latest-project-card img {
    width: 70px;
    height: 70px;
  }

  /* About */
  .info-photo-text {
    flex-direction: column;
  }

  .info-photo img {
    width: 100%;
    height: 100%;
  }

  .info-text {
    padding-left: 0px;
  }
}